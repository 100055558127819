import { sourceCacheSet, regSkipCacheSet } from './cache'

import type { RetentionSource } from '../types'

function detect(): RetentionSource {
  const referrer = document.referrer
  if (referrer.includes('google')) return 'search'
  if (referrer.includes('bing')) return 'search'
  if (referrer.includes('yandex')) return 'search'
  if (!referrer) return 'direct'
  return 'organic'
}

/**
 * Used on the login page via mail
 */
export function sourceEmailInit() {
  sourceCacheSet('email', true)
}

/**
 * Used on public pages for tracking referrer source
 */
export function sourcePablicInit() {
  sourceCacheSet(detect())
}

/**
 * Used on a page with paid traffic
 */
export function sourcePaidInit() {
  sourceCacheSet('paid', true)
}

/**
 * Used to skip after registration
 */
export function regSkipInit() {
  regSkipCacheSet()
}
